/* NEWSLETTER POPUP */

.newsletterSubs {
  text-align: center;
  align-items: center;

  font-size: 1.2vw;
  position: fixed;
  right: 30%;
  top: 20%;
  background-color: white;
  height: 10em;
  width: 18em;
  z-index: 9999;
  padding-top: 2.8em;
}
@media screen and (max-width: 768px) {
  .newsletterSubs {
    height: 15em;
  }
}
@media screen and (max-width: 267px) {
  .newsletterSubs {
    height: 30em;
  }
}
.newsLetterButton {
  padding-left: 10px;
  cursor: pointer;
  position: fixed;
  bottom: 50px;
  left: 9px;
  border-radius: 0px;

  background-color: white;
  height: 2em;
  width: 5em;
  z-index: 10;
  opacity: 0.6;
}
@media screen and (max-height: 547px) {
  .newsLetterButton {
    display: none;
  }
}
.newsLetterCloseButton {
  align-items: center;

  position: absolute;
  border-radius: 0px;

  border: black solid 0.1px;
  background-color: white;
  top: -1px;
  right: -1px;
  font-size: 1.2vw;
}
