.content {
  float: right;
}
.flexContainer {
  height: 600px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
  align-content: center;
  width: 100%;
}
.flexItem {
  margin: 20px;

  flex-wrap: wrap;
  height: 200px;
  width: 250px;
  overflow: hidden;
  cursor: pointer;
  background-color: white;
}

.galleryWrapper {
  text-align: center;
  background-color: white;
  width: 100%;

  /* padding-right: 50px; */
}
.fullScreenContainer {
  position: fixed;
  top: 0;
  overflow: hidden;

  text-align: center;
  overflow: hidden;
}
.fullScreenDimmer {
  background-color: black;
  position: fixed;
  z-index: 9998;
  opacity: 0.8;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  padding: 50px;
}

.fullScreenContent {
  position: fixed;
  width: 100vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;

  z-index: 9998;
}
.imageContainer {
  width: 100%;

  text-align: center;
  position: relative;
  z-index: 9999;
  max-height: 100vh;
  max-width: 100vw;
}
.closeFullscreenButton {
  font-weight: 1;
  position: fixed;
  right: 0;
  top: 0;
  height: 3vw;
  width: 3vw;
  overflow: hidden;
  z-index: 9999;
  border-radius: 0;
  color: white;
  background-color: none !important;

  border: solid 1px white;
  cursor: pointer;
}
.closeFullscreenButton img {
  font-size: 3vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}
.prevNextWrapper {
  position: absolute;

  width: 100%;
  height: 100%;
  top: 0%;
  z-index: 9999;
}
.prev {
  width: 50%;
  height: 100%;
  cursor: w-resize;
  float: left;
  z-index: 9999;
}
.next {
  width: 50%;
  height: 100%;
  cursor: e-resize;
  float: right;
}
.galleryButton {
  border: none;
  background: none;
  border-radius: 0;
  width: 10%;
  border: solid 0.02px white;
}
.galleryButton:focus {
  background: none;
  outline: none;
  border: none;
}
.galleryButton:hover {
  border: solid 0.02px black;
  height: 40px;
}

.imageCollectionImg {
  max-height: 80vh;
  max-width: 90%;
}
.imageCollectionThumb {
  max-height: 100%;
  max-width: 120%;
}

.galleryTitle {
  position: fixed;
  left: 22%;
  background-color: white;
  z-index: 50;
  top: 0;
  border: solid 1px lightgrey;
  font-size: 1vw;
  height: 4em;
  line-height: 4em;
  padding-left: 1vw;
  padding-right: 1vw;
  color: gray;
}

.ControlBarWrapper {
  font-size: 12px;
  font-weight: bold;
  position: fixed;
  background-color: white;
  bottom: 0;
  right: 0;
  z-index: 9997;
  width: 250px;
  height: 150px;
  text-align: left;
  margin-bottom: 30px;
  margin-right: 50px;
  padding: 25px;
}
.InfoBarWrapper {
  text-shadow: -1px 0 #3a3a3a, 0 1px #3a3a3a, 1px 0 #3a3a3a, 0 -1px#3a3a3a;

  font-size: 0.8vw;
  font-weight: bold;
  position: fixed;
  top: -2%;
  color: white;
  /* border: solid black 1px; */
  z-index: 9999;
  width: 24em;
  height: 14em;
  text-align: left;
  left: 0;
  margin-right: 50px;
  margin-top: 10px;
  padding: 25px;
}
.title {
  font-size: 1.3vw;
}
@media screen and (max-width: 800px) {
  .InfoBarWrapper {
    font-size: 8px;
  }
  .title {
    font-size: 10px;
  }

  .buttonWrapper {
    cursor: pointer;
    font-weight: 50;
    font-size: 10px;
  }

  .closeFullscreenButton {
    height: 30px;
    width: 30px;
  }
  .galleryTitle {
    font-size: 8px;
    height: 4em;
  }
}
@media screen and (max-width: 400px) {
  .contentWrapper {
    padding-left: 0px;
  }
  .imageCollectionThumb {
    max-width: 200px;
  }
}
