/* MAIN */

.App {
  z-index: 0;
  height: 100vh;
  overflow: hidden;
  margin-bottom: 100px;
  position: relative;
  display: block;
  background-color: white; /* rgb(240, 240, 240); */
}

/* LOGO */
.logo {
  font-size: 4vw;
  text-align: left;

  padding-bottom: 30px;
  transition-property: all;
  transition-duration: 0.5s;
  margin-bottom: 10px;
  width: 100%;
}
.logo:hover {
  filter: hue-rotate(100deg);
  color: red;
}

/*CONTENT */

.contentWrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;

  padding-top: 60px;
  text-align: center;
  right: 0;
  height: 100%;
  position: fixed;

  width: 78%;
}

.flexContainer {
  height: 600px;
  position: relative;
  height: 100%;
  display: flex;

  background-color: white;
  align-content: center;
  width: 100%;
}
.flexItem {
  margin: 20px;

  flex-wrap: wrap;
  height: 200px;
  width: 250px;
  overflow: hidden;
  cursor: pointer;
  background-color: white;
}

/* INSTAGRAM LINK */
.instaLink {
  padding-left: 10px;
  cursor: pointer;
  position: fixed;
  bottom: 100px;
  left: 9px;
  border-radius: 0px;

  background-color: white;
  height: 2em;
  width: 5em;
  z-index: 10;
  opacity: 0.6;
}
@media screen and (max-height: 547px) {
  .instaLink {
    display: none;
  }
}

/* HOMEPAGE */
.homeLink {
  color: black;
}
.homeWrapper {
  overflow: hidden;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* CREDITS BLOCK */
.credits {
  text-align: center;
  font-size: 0.8vw;
  transform: rotate(-90deg);
  position: fixed;
  right: -4%;
  z-index: 9999;
  width: 20em;
  bottom: 15%;
  height: 2em;
  line-height: 1.7em;
  border: 1px lightgray solid;
  background-color: white;
}

@media screen and (max-width: 800px) {
  .credits {
    font-size: 7px !important;

    right: -17% !important;
    width: 25em;
  }
}
