input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.contact {
  font-size: 1.2vw;
  /* text-align: left; */
}
.contactInfoBox {
  width: 100%;
  margin-left: 29%;

  font-size: 1.2vw;
  line-height: 2vw;
  /* color: gray; */
  text-align: left;
  /* border: solid 1px black; */
}
.inputContainer h3 {
  font-size: 1.8vw;
}
.icon {
  color: gray;
}
@media screen and (max-width: 800px) {
  .inputContainer h3,
  .contactInfoBox,
  .contact,
  .inputContainer {
    font-size: 8px;
  }
  .inputContainer h3 {
    font-size: 13px;
  }
  .contactInfoBox {
    line-height: 10px;
  }
}

/* ContactForm */

.inputContainer {
  position: absolute;
  margin-top: 8%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-bottom: 4vw;
  left: 50%;
  transform: translateX(-50%);
}

#inputItem input[type='text'] .inputItem,
#imageInput textarea[type='text'] {
  box-shadow: none;
  color: lightgray;
  width: 33vw;
  border-radius: 0;
  border: solid lightgray 1px;
  font-size: 1.6vh;
  height: 4vh;

  /*   border: none;
  background-color: rgb(235, 235, 235);
  border: red 0.5 solid !important; */
}
input:active {
  border: none;
  box-shadow: none;
}
#messageInput textarea[type='text'] {
  /*  height: 150px; */
}
.contactForm {
  position: relative;
  margin-top: 50px;
}
.submitButton {
  cursor: pointer;
  color: gray;
  border-radius: 0px !important;
}
@media screen and (max-width: 800px) {
  #inputItem input[type='text'] {
    font-size: 8px;
  }
}
::placeholder {
  color: rgb(180, 180, 180);
}
:placeholder-shown {
  /* Chrome, Firefox, Opera, Safari 10.1+ */

  color: rgb(180, 180, 180);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(180, 180, 180);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(180, 180, 180);
}
