/* HOMEPAGE */
.homeLink {
  color: black;
}
.homeWrapper {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
}
.homeWrapper h1 {
  cursor: pointer;
  position: fixed;
  font-size: 10vw;
  z-index: 9999;
  top: 50%;
  left: 18%;
  /* transform: translateX(-50%); */
  transform: translateY(-50%);

  color: white;
}
.homepageImageContainer {
  position: fixed;

  top: 50%;

  transform: translateY(-50%);
  z-index: 1;
}
