.menuWrapper {
  position: fixed;
  overflow: hidden;
  padding: 0px;
  background-color: white;
  height: 100%;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  float: left;

  width: 22%;
  min-width: 80px;
  font-size: 1.2vw;
  padding: 0.2em;
  padding-left: 1.5em;
}
.verticalDivider {
  transform: rotate(-deg);
  position: fixed;
  left: 22%;
  height: 100%;
  width: 1px;
  border-left: 1px lightgray solid;
  overflow: hidden;
  z-index: 500;
}

ul.nav {
  z-index: 200;
  padding: 0px;
  position: relative;
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
}
ul.nav li {
  line-height: 1.6em;
}
ul.nav a {
  padding: 0px;
  text-decoration: none;
  font-weight: 600;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0px;
  line-height: 1.6em;

  color: black;
}
ul.nav a:hover {
  background: rgba(#000, 0.1);
  color: #f8a79d;
}
ul.nav a:focus {
  background: rgba(#000, 0.1);
  color: #f8a79d;
}
ul.nav a:active {
  background: rgba(#000, 0.1);
  color: #f8a79d;
}

ul.nav li {
  display: block;
}
.dropdown-btn {
  cursor: pointer;
  padding: 0px;
  margin: none;
  display: block;
  background: none;
  border: none;

  font-weight: 600;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0px;
  line-height: 1.4em;
}
.dropdown-btn:hover {
  background-color: rgba(#000, 0.1);
  color: #ff5842;
}
.dropdown-btn:focus {
  background: none;
  outline: none;
  border: none;
  padding: 0px;
}
.dropdown-btn:active {
  color: #aaa9a9;
}
.dropdown-container {
  overflow: hidden;
  height: 0px;
  opacity: 1;
  transition-property: all;
  transition-duration: 0.5s;
  padding-left: 15px;
}

@media screen and (max-width: 800px) {
  .menuWrapper {
    font-size: 8px;
  }
  .dropdown-btn {
    font-size: 8px;
  }

  
}
